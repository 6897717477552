import { Spin } from 'antd';
import styles from './index.module.scss';
import { useTranslations } from 'next-intl';

export type CompareCardProps = {
  title: string;
  today: string;
  yesterday: string;
  isLoading: boolean;
};

const CompareCard: React.FC<CompareCardProps> = ({ title, today = 0, yesterday = 0, isLoading }) => {
  const t = useTranslations('dashboard');
  const sumRate = (num1: number, num2: number) => {
    if (typeof num1 !== 'number' || typeof num2 !== 'number') {
      return 0;
    }
    if (num1 === 0) {
      // 今天未有增长
      return 0;
    }
    if (num2 === 0) {
      // 昨天无增长，今天有增长 => 100%
      return 100;
    }

    const value: number = Number(((num1 - num2) / num2).toFixed(2));
    return Math.floor(value * 100);
  };
  const compare = sumRate(Number(today), Number(yesterday));
  return (
    <div className={styles['compareCard']}>
      <span className={styles['compareCard__title']}>{title}</span>
      {isLoading ? (
        <div className={styles['compareCard__loading']}>
          <Spin></Spin>
        </div>
      ) : (
        <>
          <p>{today}</p>
          <div className={styles['compareCard__itemInfo']}>
            <span>
              {t('yesterday')}:
              {yesterday}
            </span>
            <span>
              {t('compareYesterday')}: <strong className={compare > 0 ? '' : styles['--danger']}>{compare}%</strong>
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default CompareCard;
