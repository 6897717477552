'use client';

import { setHeaderTitle } from '@/lib/features/configSlice';
import { useAppDispatch } from '@/lib/hooks';

const DispatchHeaderTitle: React.FC<{ title: string }> = ({ title }) => {
  const dispatch = useAppDispatch();
  dispatch(setHeaderTitle(title));
  return null;
};

export default DispatchHeaderTitle;
